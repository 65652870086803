import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { navigate, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../components/Layout';
import { SectionInstagramPosts } from '../components/InstagramPosts';

import { downloadFile } from '../utils/common';
import { parseYoutubeLink } from '../utils/common';

import imgBlockL from '../assets/images/accreditation/Photo 2 (4).png';
import imgBlockR from '../assets/images/telf-guide/tefl-ebook-cover2.png';
import iconTick from '../assets/images/svg/tick.svg';
import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

// import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';
import fileTEFLIberiaBrochure from '../assets/files/5_things_to_consider_when_choosing_a_TEFL_course_.pdf';

import '../assets/styles/pages/tefl-guide.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const EMPTY_BROCHURE_DATA = {
  firstName: '',
  userEmail: '',
};

const TeflGuide = ({ data }) => {
  const allPrismicGraduates = data.allPrismicGraduates.edges;
  // const firstGraduate = allPrismicGraduates.filter(
  //   ({
  //     node: {
  //       data: { pinned },
  //     },
  //   }) => pinned === true
  // );
  const otherGraduates = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned !== true
  );

  const allPrismicInstagramPosts = data.allPrismicInstagramPosts.edges;

  const [formData, setFormData] = useState(EMPTY_FORM_DATA);
  const [shortBrochureData, setShortBrochureData] = useState(EMPTY_BROCHURE_DATA);

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChangeShortBrochure = ({ target: { name, value } }) => {
    setShortBrochureData({ ...shortBrochureData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const sourceLabel = 'Ebook';

    const isShortBrochure =
      shortBrochureData.firstName.length > 0 && shortBrochureData.userEmail.length > 0;

    const MailchimpData = {
      FNAME: isShortBrochure ? shortBrochureData.firstName : formData.firstName,
      LNAME: isShortBrochure ? '' : formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: sourceLabel, // Source
    };

    const requestBody = isShortBrochure
      ? JSON.stringify({
          ...shortBrochureData,
          sourceLabel,
          userFromGoogleAds: window?.userFromGoogleAds,
          userFromFacebookAds: window?.userFromFacebookAds,
          userFromGoOverseas: window?.userFromGoOverseas,
          userFromGoAbroad: window?.userFromGoAbroad,
        })
      : JSON.stringify({
          ...formData,
          sourceLabel,
          userFromGoogleAds: window?.userFromGoogleAds,
          userFromFacebookAds: window?.userFromFacebookAds,
          userFromGoOverseas: window?.userFromGoOverseas,
          userFromGoAbroad: window?.userFromGoAbroad,
        });

    await fetch('/api/download-brochure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    addToMailchimp(
      isShortBrochure ? shortBrochureData.userEmail : formData.userEmail,
      MailchimpData
    );
    downloadFile('5_things_to_consider_when_choosing_a_TEFL_course.pdf', fileTEFLIberiaBrochure);

    setFormData(EMPTY_FORM_DATA);
    setShortBrochureData(EMPTY_BROCHURE_DATA);

    navigate('/tefl-iberia-brochure/thank-you-guide');
  };

  return (
    <>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1389098578493723');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height=“1” width=“1" style=“display:none” src=“https://www.facebook.com/tr?id=1389098578493723&ev=PageView&noscript=1” />`}
        </noscript>
        {/* <!-- End Meta Pixel Code --> (edited)  */}
      </Helmet>

      <Layout
        title="5 Things to Consider When Choosing a TEFL Course"
        description="Find out how to get started as an online teacher with our step-by-step guide."
      >
        <div className="tefl-guide-page">
          <section className="c-first-section c-first-section--sm">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 col-md-5">
                  <h1 className="c-first-section__title">
                    Want to study TEFL in Spain but not sure where to start? <br />
                    Let us help you!{' '}
                  </h1>
                  <p className="c-first-section__description">
                    Get our guide on: “5 things to consider when choosing a TEFL Course”
                  </p>
                </div>
                <div className="col-12 col-lg-6 col-md-7">
                  <div className="block_l">
                    <form onSubmit={handleSubmit} className="form">
                      <h1 className="c-title-22 form__title">Get Your TEFL Guide Now!</h1>

                      <label className="form__first-name">
                        <p className="c-text-14 text-under">First Name</p>
                        <input
                          className="input__first-name"
                          type="text"
                          placeholder="John"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                      <label className="form__last-name">
                        <p className="c-text-14 text-under">Last Name</p>
                        <input
                          className="input__last-name"
                          type="text"
                          placeholder="Smith"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                      </label>

                      <label className="form__first-name email">
                        <p className="c-text-14 text-under">Email</p>
                        <input
                          className="input__email"
                          type="email"
                          placeholder="johnsmith@gmail.com"
                          name="userEmail"
                          value={formData.userEmail}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                      <a href={fileTEFLIberiaBrochure}>
                        <button type="submit" className="form__btn c-btn c-btn--red">
                          Download my brochure
                        </button>
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="wrapper-with-background-image">
            <section className="second-section">
              <div className="container">
                <div className="row top">
                  <div className="col-12 col-md-6">
                    <div className="block_r--bot">
                      <h2 className="c-title-34 block__title">What’s In the Guide? </h2>
                      <div className="c-benefit-list">
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Accreditation - What is an accredited TEFL course?
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Course Content - What is considered good course content?
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Teaching Practice - How much teaching should you do?
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Career Support - Does the course offer job assistance?
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Cost - What is considered a reasonable course cost?
                          </p>
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="block_l--bot">
                      <img className="block_l--bot__img" src={imgBlockR} alt="tefl-ebook-cover" />
                    </div>
                  </div>
                </div>
                <div className="row bot">
                  <div className="col-12 col-md-6">
                    <div className="block_l--bot">
                      <img
                        className="block_l--bot__img"
                        src={imgBlockL}
                        alt="  Who Is TEFL Iberia And How Can We Help?"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="block_r--bot">
                      <h2 className="c-title-34 block__title">
                        Who Is TEFL Iberia And How Can We Help?
                      </h2>
                      <p className="c-text-18 block__subtitle">
                        We’ve trained hundreds of new teachers and they’ve all got great things to
                        say about the school. They’ll definitely mention:
                      </p>
                      <div className="c-benefit-list">
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">The small groups (12 maximum, not 20).</p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            Varied teaching practise with real students (big & small groups,
                            beginner to advanced levels).
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">Excellent support from our staff.</p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">Being instilled with the confidence to teach.</p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">
                            The first-class job opportunities available to them afterwards (we work
                            very hard to make TEFL Iberia on of the most well-known TEFL brands).
                          </p>
                        </div>{' '}
                        <div className="benefit">
                          <img src={iconTick} alt="iconTick" />
                          <p className="c-text-16">Dedicated course coordinator.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="video-reviews-section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="c-title-34 video-reviews-section__title">
                      Don’t just take our word for it
                    </h2>
                  </div>
                  {otherGraduates.splice(0, 3).map(({ node }) => (
                    <div className="col-lg-4 col-sm-6 col-12" key={node.id}>
                      <div className="review-item">
                        <div className="review-item__video">
                          <iframe
                            width="560"
                            height="315"
                            src={parseYoutubeLink(node?.data?.video?.embed_url)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                        <h3 className="c-text-16 review-item__title">{node?.data?.title?.text}</h3>
                        <p className="c-text-14 review-item__description">
                          {node?.data?.description?.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <SectionInstagramPosts
              title={() => 'Explore Our Social Media'}
              //   description={() => 'Keep up to date with our students on Instagram'}
              allPrismicInstagramPosts={allPrismicInstagramPosts}
            />
          </div>
          <section className="third-section">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-12 ">
                  <div className="third-section__wrapper">
                    <h2 className="c-title-38 third-section__title">
                      Download our brochure and get information on{' '}
                      <span className="colored no-link">
                        teaching English as a foreign language.
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="col-md-4 col-12 ">
                  <form onSubmit={handleSubmit} className="form">
                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        value={shortBrochureData.firstName}
                        onChange={handleInputChangeShortBrochure}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Email</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="userEmail"
                        value={shortBrochureData.userEmail}
                        onChange={handleInputChangeShortBrochure}
                        required
                      />
                    </label>
                    <a href={fileTEFLIberiaBrochure}>
                      <button type="submit" className="form__btn c-btn c-btn--red">
                        Download Guide <IconArrowRight />
                      </button>
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allPrismicGraduates(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            video {
              embed_url
            }
            pinned
          }
        }
      }
    }
    allPrismicInstagramPosts(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date(formatString: "DD MMMM")
            image {
              url
            }
            description {
              text
            }
            tags
            instagram_link {
              url
            }
          }
        }
      }
    }
  }
`;

export default TeflGuide;
